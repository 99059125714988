import React from "react";
import {useObjectLink} from "@rn-cantons/react-link";
import extractProps from "./selector";
import {PageStore, Page} from "./types";

export const LogicContext = React.createContext<Partial<Page>>({});


export class Logic{
   public static store: PageStore = {
       preloading: true,
       preloadText: 'Ladataan...',
       error: false,
   }
   public static link: any
   public static value: any
}

export const LogicProvider = (props: any) => {
    const link = useObjectLink<PageStore>({
        preloading: true,
        preloadText: 'Ladataan...',
        error: false,
    });

    const value = extractProps(link);
    Logic.store = link.value;
    Logic.link = link;
    Logic.value = value;
    return <LogicContext.Provider {...props} value={value} />
}

export const useLogic = () => {
    return React.useContext(LogicContext) as Page;
}
