import authFetch from "./utils/authFetch";
import {ca} from "date-fns/locale";

export enum Status {
    Created = "created",
    Working = "working",
    Ready = "ready",
    Complete = "complete",
    Paid = "paid"
}


export interface GetContainerNumberRequest {
    ski: string
    container: string
    order: string
}

const putSkiToContainer = async ({container, ski, order}:GetContainerNumberRequest) => {
    try{
        const r = await authFetch(`/api/put-to-container?ski=${ski}&container=${container}&order=${order}`);
        return r.json();
    }catch (e){
        const error = await (e as any).response.json();
        return {error}
    }

}

export default putSkiToContainer;
