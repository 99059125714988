export interface GetBookingInfoRequest {
    id: string
}

const getBookingInfo = async ({id}: GetBookingInfoRequest) => {
    console.log('getBookingInfo', id);
    const r = await fetch(`/api/get-booking-info?id=${id}`);
    return r.json();
}

export default getBookingInfo;
