import {createSelector, createStructuredSelector} from 'reselect'
import Preloader from "../../components/Preloader";
import authStateChanged, {Unsubscribe} from "../firebase/authStateChanged";
import {ObjectLink} from "@rn-cantons/react-link";
import {ActionsState, PageStore} from "../types";
import {prop, set} from '../utils';
import {findByCode, onOrderCompleted, onSkiPut} from "../actions";
import {Logic} from "../index";


const onAuthStateChanged = createSelector(
    set('preloading'),
    set('firebaseUser'),
    set('preloadText'),
    (setPreloading, setUser, setText) => () => {
        console.log('setup onAuthStateChanged')
        return authStateChanged((user) => {
            setUser(user);
            console.log('onAuthStateChanged success', user)
            setPreloading(false);
        }, () => {
            console.log('onAuthStateChanged reject')
            setPreloading(false);
        })
    }
)

export interface AuthProps {
    onAuthStateChanged: () => Unsubscribe
    listenBarcode: () => Unsubscribe
    preloading: boolean
    error: boolean
    preloader: any
    email: string
    photo: string
}

let code = "";

//@ts-ignore
const onBarcodeSubmit = createSelector(
    set('actionsState'),
    (setState) =>  async () => {

        console.log('onBarcodeSubmit', Logic.value.code)
        if(Logic.store.actionsState === ActionsState.ORDER){
            console.log('codes', Number(code) === Number(Logic.value.code), {code, stored_code: Logic.store.code})
            if(code === Logic.store.code){
                const onOrder = onOrderCompleted(Logic.link);
                console.log('on order complete', {code})
                await onOrder()
                setState(ActionsState.ORDER_COMPLETED);
            }else{
                alert(`code is not valid scanned code: ${code}, working code: ${Logic.store.code}`)
            }
        }else if(Logic.store.actionsState === ActionsState.ORDER_COMPLETED){
            if(code === Logic.store.code){
                const put = onSkiPut(Logic.link);
                await put();
                setState(ActionsState.SELECT);
            }else{
                alert(`code is not valid scanned code: ${code}, working code: ${Logic.store.code}`)
            }
            const put = onSkiPut(Logic.link);
            put()
        }else{
            const find = findByCode(Logic.link);
            setState(ActionsState.ORDER_SEARCHING);
            console.log('enter code ', {code})
            // setCode(code)
            find(code)
            code = ''
        }
    }
)

const listenBarcode = createSelector(
    onBarcodeSubmit,
    (onBarcodeSubmit) => () => {
        const listener = async (e: any) => {
            if (code.length === 0) {
                setTimeout(() => {
                    code = ""
                }, 500)
            }

            if (e.key === 'Enter') {
                //@ts-ignore
                onBarcodeSubmit();
            } else {
                const num = Number(e.key);
                if (!isNaN(num)) {
                    code = `${code}${num}`
                }
            }
            // e.preventDefault();
            // console.log({e})
        };
        console.log('listenBarcode')
        document.addEventListener('keypress', listener);
        return () => document.removeEventListener('keypress', listener)
    }
)


const loginPageSelector = createStructuredSelector<ObjectLink<PageStore>, AuthProps>({
    onAuthStateChanged,
    listenBarcode,
    preloading: createSelector(prop('preloading'), (preloading) => !!preloading),
    error: createSelector(prop('error'), (error) => !!error),
    email: createSelector(prop('firebaseUser'), (user) => user?.email),
    photo: createSelector(prop('firebaseUser'), (user) => {
        return user?.photoURL
    }),
    preloader: createSelector(
        prop('preloadText'),
        (text) => {
            return <Preloader>{text}</Preloader>
        }
    )
})


export default loginPageSelector