import authFetch from "./utils/authFetch";

export enum Status {
    Created = "created",
    Working = "working",
    Ready = "ready",
    Complete = "complete",
    Paid = "paid"
}


export interface GetContainerNumberRequest {
    order: string
}

const getContainerNumber = async ({order}:GetContainerNumberRequest) => {
    const r = await authFetch(`/api/get-container?id=${order}`);
    return r.json();
}

export default getContainerNumber;