import login from './login'
import actions from './actions'
import auth from './auth'
import smsInfo from './smsInfo'
import {ObjectLink} from '@rn-cantons/react-link'
import {PageStore} from "./types";
import {createSelector} from "reselect";


const isUser = (link: ObjectLink<PageStore>) => !!link.value.firebaseUser;

enum Page {
    SMSInfo = 'BookingSmsInformation',
    Login = 'Login',
    Actions = 'Actions'
}

const page = createSelector(
    isUser,
    () => window.location.pathname,
    (isUser, location: string) => {
        const isSMSPage = location.startsWith('/b/')
        if (isSMSPage) {
            return Page.SMSInfo
        }

        if(isUser){
            return Page.Actions
        }

        return Page.Login;
    }
)

const authProps = (link: ObjectLink<PageStore>) => auth(link);

const actionsProps = createSelector((link: ObjectLink<PageStore>) => link, page, (link, page) => {
    console.log('actionsProps', {page})
    switch (page){
        case Page.Actions:
            //@ts-ignore
            return actions(link)
        case Page.Login:
            //@ts-ignore
            return login(link);
        case Page.SMSInfo:
            //@ts-ignore
            return smsInfo(link)
    }
})

export default createSelector(
    actionsProps,
    authProps,
    page,
    (actions, auth, page) => ({...auth, ...actions, page})
)
