import * as React from "react"

function Preloader({children, ...props}: React.SVGProps<SVGSVGElement>) {
    return (
        <div className={'preloader-wrapper'}>

            <style>
                {
                    `
                      .pl {
                        width: 100px;
                        height: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }

                      .pl__ring, .pl__ball {
                        animation: ring 2s ease-out infinite;
                      }

                      .pl__ball {
                        animation-name: ball;
                      }

                      /* Animation */
                      @keyframes ring {
                        from {
                          stroke-dasharray: 0 257 0 0 1 0 0 258;
                        }
                        25% {
                          stroke-dasharray: 0 0 0 0 257 0 258 0;
                        }
                        50%, to {
                          stroke-dasharray: 0 0 0 0 0 515 0 0;
                        }
                      }

                      @keyframes ball {
                        from, 50% {
                          animation-timing-function: ease-in;
                          stroke-dashoffset: 1;
                        }
                        64% {
                          animation-timing-function: ease-in;
                          stroke-dashoffset: -109;
                        }
                        78% {
                          animation-timing-function: ease-in;
                          stroke-dashoffset: -145;
                        }
                        92% {
                          animation-timing-function: ease-in;
                          stroke-dashoffset: -157;
                        }
                        57%, 71%, 85%, 99%, to {
                          animation-timing-function: ease-out;
                          stroke-dashoffset: -163;
                        }
                      }

                      .label {
                      font-family: "Open Sans", sans-serif;
                        display: flex;
                        color: #fff;
                        font-size: 1.3em;
                      }
                      .preloader-wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      }


                    `
                }
            </style>
            <p className={'label'}>{children}</p>
            <svg
                className="pl"
                width={100}
                height={100}
                viewBox={'0 0 200 200'}
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <defs>
                    <linearGradient id="a" x1={1} y1={0.5} x2={0} y2={0.5}>
                        <stop offset="0%" stopColor="hsl(346,95%,37%)"/>
                        <stop offset="100%" stopColor="hsl(346,95%,50%)"/>
                    </linearGradient>
                    <linearGradient id="b" x1={0} y1={0} x2={0} y2={1}>
                        <stop offset="0%" stopColor="hsl(346,95%,37%)"/>
                        <stop offset="100%" stopColor="hsl(346,95%,37%)"/>
                    </linearGradient>
                </defs>
                <circle
                    className="pl__ring"
                    cx={100}
                    cy={100}
                    r={82}
                    fill="none"
                    stroke="url(#a)"
                    strokeWidth={36}
                    strokeDasharray="0 257 1 257"
                    strokeDashoffset={0.01}
                    strokeLinecap="round"
                    transform="rotate(-90 100 100)"
                />
                <path
                    className="pl__ball"
                    stroke="url(#b)"
                    strokeWidth={36}
                    strokeDasharray="1 165"
                    strokeLinecap="round"
                    d="M100 18l.01 164"
                />
            </svg>
        </div>

    )
}

export default Preloader
