import {createSelector, createStructuredSelector} from 'reselect'
import emailAuth from "../firebase/emailAuth";
import {ObjectLink} from "@rn-cantons/react-link";
import {PageStore} from "../types";
import {prop, set, setupInputUpdateHandler} from '../utils';


const submitHandler = createSelector(
    prop('email'),
    prop('password'),
    set('error'),
    (email, password, setError) => {
        return async () => {
            try {
                await emailAuth(email as string, password as string)
                setError(false)
            } catch (e) {
                setError(true)
            }

        }
    }
)


const password = createSelector(
    setupInputUpdateHandler('password'),
    (onChange: any) => ({
        props: {
            type: 'password',
            onChange,
        },
    })
)

const email = createSelector(
    setupInputUpdateHandler('email'),
    (onChange: any) => ({
        props: {
            onChange,
        },
    })
)

const submit = createSelector(
    submitHandler,
    (onClick: any) => ({
        props: {
            onClick,
            style: {
                cursor: 'pointer'
            }
        },
    })
)

export interface LoginProps {
    page: 'Login',
    email?: any
    password?: any
    submit?: any
}


const loginPageSelector = createStructuredSelector<ObjectLink<PageStore>, LoginProps>({
    page: () => 'Login',
    email,
    password,
    submit,
})


export default loginPageSelector
