// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicOrderItem,
  DefaultOrderItemProps
} from "./plasmic/hiihtocenter/PlasmicOrderItem";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface OrderItemProps extends Omit<DefaultOrderItemProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultOrderItemProps altogether and have
// total control over the props for your component.
export interface OrderItemProps extends DefaultOrderItemProps {
}

function OrderItem_(props: OrderItemProps, ref: HTMLElementRefOf<"div">) {
  return <PlasmicOrderItem root={{ ref }} {...props} />;
}

const OrderItem = React.forwardRef(OrderItem_);
export default OrderItem;
