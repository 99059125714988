import {getAuth, getIdToken} from 'firebase/auth'
import app from "../../firebase/app";

export default async function authFetch(input: RequestInfo, init: RequestInit = {}) {
    const auth = getAuth(app);
    const token = auth.currentUser ? await getIdToken(auth.currentUser) : "";
    const authInit: RequestInit = {
        ...init, headers: {
            Authorization: `Bearer ${token}`
        }
    }
    try {
        return await fetch(input, authInit)
    }catch(e){
        throw e
    }
}