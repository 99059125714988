import React, {useEffect} from "react";
import {PlasmicComponent, PlasmicRootProvider} from '@plasmicapp/loader-react';
import {PLASMIC} from './init';
import {LogicProvider, useLogic} from "./app/index";
import {Page} from "./app/types";
import BookingSmsInformation from "./components/BookingSmsInformation";
import Login from "./components/Login";
import Actions from "./components/Actions";

const pages = {
    "BookingSmsInformation": BookingSmsInformation,
    "Login": Login,
    "Actions": Actions,
}

function PlasmicApp() {
    const props: Page = useLogic();
    console.log('PlasmicApp', {props})

    useEffect(() => {
        //@ts-ignore
        console.log({state: props.state})
        //@ts-ignore
    }, [props.state])

    useEffect(() => {
        return props.onAuthStateChanged()
    }, [])

    useEffect(() => {
        //@ts-ignore
        return props.listenBarcode();
    }, [])
    const Page = pages[props.page];
    return (
        <PlasmicRootProvider loader={PLASMIC}>
            <Page {...props}/>
        </PlasmicRootProvider>
    );
}

const Root = () => {
    return (
        <LogicProvider>
            <PlasmicApp/>
        </LogicProvider>
    )
}


export default Root;
