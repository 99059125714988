import {createSelector, createStructuredSelector} from 'reselect'
import {ObjectLink} from "@rn-cantons/react-link";
import {PageStore, SMSInfoState} from "../types";
import {prop, set} from "../utils";
import getBookingInfo from "../api/getBookingInfo";
import {format} from "date-fns";
import OrderItem from "../../components/OrderItem";
import ServiceItem from "../../components/ServiceItem";


const bookingIdFromLocation = (p: ObjectLink<PageStore>) => {
    const matchResult = window.location.pathname.match(/\/b\/(.*)/);
    console.log({location: window.location.pathname, matchResult})
    return matchResult ? matchResult[1] : "";
}

const image = createSelector(
    bookingIdFromLocation,
    (bookingId) => {
        return {
            src: `https://europe-west1-hiihtocenter.cloudfunctions.net/getBarcodeImg?number=${bookingId}`
        }
    }
)


class GetBooking {
    requesting = false
    booking = undefined

    public getBooking(bookingId: string, setBooking: (booking: any) => void) {
        if(this.booking){
            return this.booking
        }
        if (!this.requesting) {
            this.requesting = true;
            getBookingInfo({id: bookingId}).then(
                booking => {
                    console.log('booking', {booking});
                    this.booking = booking
                    setBooking(booking);
                }
            )
            return  undefined
        }

    }

}

const gb = new GetBooking();


const booking = createSelector(
    prop('booking'),
    set('booking'),
    bookingIdFromLocation,
    (booking, setBooking, bookingId) => {
        if (booking) {
            console.log('booking', booking)
            return booking;
        } else {
            const newBooking = gb.getBooking(bookingId, setBooking);
            if(!booking && newBooking){
                setBooking(newBooking)
            }
            return booking;
        }

    }
)

const date = createSelector(
    prop('booking'),
    (booking) => {
        const date = booking?.date ? new Date(booking?.date) : undefined;
        return date ? format(date, 'dd.MM.yyyy HH:mm') : '...'
    }
)

const items = createSelector(
    prop('booking'),
    (booking) => {
        if(booking) {
            return (<>
                {
                    booking.orders?.map((o: any) => {
                        return (
                            <OrderItem
                                key={o.name}
                                brand={o.brand}
                                //@ts-ignore
                                items={
                                    <>
                                        {
                                            o.services.map((service: any) => <ServiceItem name={service.name} price={service.price} />)
                                        }</>
                                } />
                        )
                    })
                }
                </>)
        }else{
            return <h2 style={{color: "white"}}>...</h2>
        }
    }
)

const numberTxt = createSelector(
    bookingIdFromLocation,
    (bookingId) => {
        return bookingId
    }
)
const totalPrice = createSelector(
    booking,
    (booking) => {
        return booking ? `${booking.total} €` : "..."
    }
)


export interface SMSInfoProps {
    booking: any
    items: any
    loading: boolean
    date: string
    totalPrice: string
    displayBooking: boolean
    image: {
        src: string
    }
    numberTxt: string
}


const smsInfoProps = createStructuredSelector<ObjectLink<PageStore>, SMSInfoProps>({
    loading: createSelector(prop('smsInfoState'), (s) => s === SMSInfoState.LOADING),
    booking,
    totalPrice,
    date,
    items,
    displayBooking: createSelector(prop('smsInfoState'), (s) => s === SMSInfoState.DISPLAY_BOOKING_INFO),
    image,
    numberTxt,
})


export default smsInfoProps
