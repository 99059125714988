import {PlasmicComponent} from "@plasmicapp/loader-react";
import React from "react";
import {createStructuredSelector} from "reselect";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {ObjectLink, useObjectLink} from "@rn-cantons/react-link";

// import './scanner.css'

interface ScannerState {
    code: string
}


interface ScannerProps {
    back: () => void
    onScan: (code: string) => void
    active: boolean
}


interface Scanner extends ObjectLink<ScannerState>, ScannerProps {

}


interface ScannerComponentProps {
    code: string
    cancel: any
    scanner: JSX.Element | null
    scanned: boolean
}

const selector = createStructuredSelector<Scanner, ScannerComponentProps>({
    scanner: (l) => {
        console.log('render scanner')
        return l.value.code.length === 0 ? <div className={'scanner'}>
            {l.active && <BarcodeScannerComponent
                torch={true}
                delay={50}
                facingMode={'environment'}
                onUpdate={(err, result: any) => {
                    console.log('scan barcode')
                    if (result) {
                        l.onScan(result.text)
                        l.update({code: result.text})
                        setTimeout(() => l.update({code: ''}), 1000)
                    };
                }}
            />}</div> : null
    },
    code: (l) => l.value.code,
    cancel: (l) => ({
        style: {cursor: 'pointer'}, onClick: () => {
            l.back()
        }
    }),
    scanned: (l) => l.value.code.length > 0,
})

const Scanner = (p: ScannerProps) => {
    const link = useObjectLink<ScannerState>({code: ""})
    const componentProps = selector({...link, ...p})
    return <PlasmicComponent componentProps={componentProps} component={'BarcodeScanner'}/>
}

export default Scanner;