// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicActions,
  DefaultActionsProps
} from "./plasmic/hiihtocenter/PlasmicActions";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import {useEffect} from "react";

import {useLogic} from "../app/index";


// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface ActionsProps extends Omit<DefaultActionsProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultActionsProps altogether and have
// total control over the props for your component.
interface ActionsProps extends DefaultActionsProps {}

function Actions_(props: ActionsProps, ref: HTMLElementRefOf<"div">) {
  return <PlasmicActions root={{ ref }} {...props} />;
}

const Actions = React.forwardRef(Actions_);
export default Actions;
