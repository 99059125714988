import {createSelector, createStructuredSelector} from 'reselect'
import {ObjectLink} from "@rn-cantons/react-link";
import {ActionsState, PageStore} from "../types";
import {prop, set, setupInputUpdateHandler} from "../utils";
import firebaseLogout from "../firebase/logout";
import {User} from "firebase/auth";
import Preloader from "../../components/Preloader";
import getOrder from "../api/getOrder";
import {PlasmicComponent} from "@plasmicapp/loader-react";
import React from "react";
import {OrderToCell, orderToCellSelector} from "./components/orderToCell";
import changeStatus, {Status} from "../api/changeStatus";
import getContainerNumber from "../api/getContainerNumber";
import putSkiToContainer from "../api/putSkiToContainer";
import getOrders from "../api/getOrders";
import Scanner from '../../components/Scanner';
import {DefaultTextInputProps} from "../../components/plasmic/hiihtocenter/PlasmicTextInput";
import {DefaultCheckboxProps} from "../../components/plasmic/hiihtocenter/PlasmicCheckbox";

const changeActionState = (state: ActionsState, actionsSelector: any = () => () => null) => createSelector(
    set('actionsState'),
    actionsSelector,
    (set, onAction) => {
        return (
            {
                props: {
                    style: {
                        "cursor": "pointer"
                    },
                    onClick: async () => {
                        try {
                            set('preloading')
                            const newState = await onAction();
                            set(newState || state);
                        } catch (e: any) {
                            console.error(e)
                            set('error')
                        }
                    }
                }
            }
        )
    }
)

//@ts-ignore
const header = createSelector(
    set('userMenuOpen'),
    prop('userMenuOpen'),
    prop('firebaseUser'),
    (setUserMenu, isOpen, firebaseUser: User) => {
        return (
            {
                props: {
                    name: firebaseUser?.email || 'Anonim',
                    logo: {
                        props: {
                            style: {
                                "cursor": "pointer"
                            },
                            onClick: () => setUserMenu(!isOpen)
                        }
                    }
                }
            }
        )
    }
)


const selectOrder = createSelector(
    set("actionsState"),
    set("order"),
    (changeState, setOrder) => async (order: any) => {
        setOrder(order);
        changeState(ActionsState.PRELOADING);
        await changeStatus({status: Status.Working, order: order.id})
        changeState(ActionsState.ORDER)
    }
)

const fetchOrder = createSelector(
    prop('code'),
    set('order'),
    set('orders'),
    set('actionsState'),
    set('orderError'),
    selectOrder,
    (code, set, setOrders, changeActionState, setError, selectOrder) => async () => {
        // console.log('2224 fetchOrder', {code})
        const orders = await getOrder(code as string);
        // console.log('2224 fetchOrder response', orders, orders.length)
        if (orders.length === 0) {
            setError('Ei löydy mitään tällä numerolla, yritä uudelleen eri numerolla.');
            console.log('2224 changed for error state')
            return ActionsState.ORDER_ERROR

        } else if (orders.length === 1) {
            selectOrder(orders[0])
        } else {
            setOrders(orders);
            return ActionsState.ORDER_SELECT
        }
    })

export const findByCode = createSelector(
    set('order'),
    set('orders'),
    set('actionsState'),
    set('orderError'),
    set('code'),
    selectOrder,
    (set, setOrders, changeActionState, setError, setCode, selectOrder) => async (code: string) => {
        // console.log('2224 fetchOrder', {code});
        setCode(code);
        const orders = await getOrder(code as string);
        // console.log('2224 fetchOrder response', orders, orders.length)
        if (orders.length === 0) {
            setError('Ei löydy mitään tällä numerolla, yritä uudelleen eri numerolla.');
            console.log('2224 changed for error state')
            changeActionState(ActionsState.ORDER_ERROR);
            return false

        } else if (orders.length === 1) {
            selectOrder(orders[0])
        } else {
            setOrders(orders);
            changeActionState(ActionsState.ORDER_SELECT)
        }
    })

const filterPasted = (orders: any[]) => orders.filter((order: any) => {
    const now = new Date().getTime();
    const orderDate = new Date(order.bookingDate).getTime();
    return orderDate > now;
});

const filterOrders = (orders: any[], filter: string) => {
    return orders.filter(order => {
        //filter by barcode
        const isBarcode = order.ski?.barcode?.toLowerCase().includes(filter.toLowerCase())

        //filter by email
        const isEmail = order.booking?.customer?.email?.toLowerCase().includes(filter.toLowerCase())

        //filter by services
        const isServices = order.services?.some((service: any)=> {
            return service?.name?.toLowerCase().includes(filter.toLowerCase())
        })

        //ski name
        const isSkiName = order.ski?.brand?.toLowerCase().includes(filter.toLowerCase())

        return isBarcode || isEmail || isServices || isSkiName;
    })
}

const filterAllOrders = (orders: any[], {filter, hide}: {filter: string, hide: boolean}) => {
    if (filter !== undefined && filter !== '') {
        orders = filterOrders(orders, filter);
    }
    if (hide) {
        orders = filterPasted(orders);
    }
    orders.sort((a: any, b: any) => {
        // convert to date
        const aDate = new Date(a.booking?.bookingDate).getTime();
        const bDate = new Date(b.booking?.bookingDate).getTime();
        return aDate - bDate;
    });

    console.log('2222234 filterAllOrders', orders.length);
    return orders;
}

const hidePasted = createSelector(
    prop('hidePasted'),
    (hidePasted) => {
        return hidePasted === undefined ? true : hidePasted;
    })

const fetchOrders = createSelector(
    set('fetchedOrders'),
    set('orders'),
    set('actionsState'),
    set('orderError'),
    hidePasted,
    prop('filter'),
    selectOrder,
    (setFetched, setOrders, changeActionState, setError, hidePasted, filter) => async () => {
        console.log('fetch orders', 'requesting.....')
        const fetchedOrders = await getOrders();
        console.log('fetch orders', 'fetched', fetchedOrders.length)
        //sort bookings by bookingDate (iso format) from oldest to newest
        const orders = filterAllOrders(fetchedOrders, {filter, hide: hidePasted});

        // console.log('fetch orders', orders)
        if (fetchedOrders.length === 0) {
            setError('Ei löydy mitään tällä numerolla, yritä uudelleen eri numerolla.');
            return ActionsState.ORDER_ERROR
        } else {
            console.log('2224 orders', {orders})
            setOrders(orders);
            setFetched(fetchedOrders);
            return ActionsState.ORDER_SELECT
        }
    });


export const onOrderCompleted = createSelector(
    prop('order'),
    set('containerNumber'),
    (order, setNumber) => async () => {
        setNumber('...');
        const res = await getContainerNumber({order: order.id});
        if (res?.number) {
            setNumber(res.number);
        } else {
            setNumber('No available containers 222')
        }
    })

export const onSkiPut = createSelector(
    prop('code'),
    prop('order'),
    prop('containerNumber'),
    set('actionsState'),
    (code, order, container) => async () => {
        await putSkiToContainer({ski: order.ski.id, container: container, order: order.id});
        await changeStatus({order: order.id, status: Status.Complete});
    })

const fade = createSelector(
    set('userMenuOpen'),
    (setUserMenu) => {
        return (
            {
                props: {
                    style: {
                        "cursor": "pointer"
                    },
                    onClick: () => setUserMenu(false)
                }
            }
        )
    }
)

const logout = createSelector(
    set('firebaseUser'),
    (setUser) => {
        return (
            {
                props: {
                    style: {
                        "cursor": "pointer"
                    },
                    onClick: async () => {
                        await firebaseLogout();
                        setUser(undefined);
                    }
                }
            }
        )
    }
)

const enterCodeBtn = changeActionState(ActionsState.ENTER_CODE)
const showOrderList = changeActionState(ActionsState.ORDER_SELECT, fetchOrders)
const submitCode = changeActionState(ActionsState.ORDER_SEARCHING, fetchOrder)
const orderSearching = changeActionState(ActionsState.ORDER_SEARCHING)
const cancelCode = changeActionState(ActionsState.SELECT)
const scanBarcode = changeActionState(ActionsState.SCAN_BARCODE, (l: ObjectLink<PageStore>) => () => {
    l.update({barcodeActive: true})
})
export const completeOrder = changeActionState(ActionsState.ORDER_COMPLETED, onOrderCompleted)
const cancelOrder = changeActionState(ActionsState.SELECT)
export const complete = changeActionState(ActionsState.DONE, onSkiPut)
const backToOrder = changeActionState(ActionsState.ORDER)


const code = createSelector(
    setupInputUpdateHandler('code'),
    (onChange: any) => ({
        props: {
            onChange,
        },
    })
)

export interface ActionsOrder {
    skiName: string
    number: string
    orderDate: string
    serviceItems: any[]
}

//@ts-ignore
export const actionsOrder = createSelector<ObjectLink<PageStore>, any>(
    (s: ObjectLink<PageStore>) => s.value.order ? orderToCellSelector(s, s.value.order) : undefined,
    (order?: OrderToCell): any => {
        if (order) {
            return {
                skiName: order?.skiName,
                number: order?.skiNumber,
                orderDate: order?.date,
                serviceItems: order.services.filter((s: any) => !!s).map((s, index) => <div
                    style={{color: 'white', fontSize: '1.2rem'}}>{`${index + 1}. ${s}`}</div>)
            }
        }
        return {}
    }
)
//@ts-ignore
export const orderTxt = createSelector<any, any>(
    (s: ObjectLink<PageStore>) => s.value.order ? orderToCellSelector(s, s.value.order) : undefined,
    prop('containerNumber'),
    (order?: OrderToCell, containerNumber?: string): any => {
        if (order) {
            return containerNumber ? `${order.skiName} ${order.skiNumber} (${containerNumber})` : `${order.skiName} ${order.skiNumber}`
        }
        return {}
    }
)

export interface ActionsProps {
    page: 'Actions'
    done: boolean
    comment: string
    preloaderContainer: any
    preloading: any
    filters?: {
        search?: DefaultTextInputProps
        hidePasted?: DefaultCheckboxProps
    }
    userMenu?: boolean
    header: any
    number: string
    cancelCode: any
    enterCodeBtn: any
    showOrderList: any
    scanBarcode: any
    scanner: any
    enterCode: boolean
    order: boolean
    orderTxt: string
    orderCompleted: boolean
    orderError: boolean
    orderSelect: boolean
    orderSearching: boolean
    scanning: boolean
    submitCode: any
    code: any
    logout: any
    fade: any
    completeOrder: any
    cancelOrder: any
    complete: any
    backToOrder: any
    preloader: any
    errorTxt: string
    orders: any
    containerNumber: string
    state?: string
}

const comment = createSelector(
    prop('order'),
    (order) => order?.comment
)


const filters = createSelector(
    prop('filter'),
    set('filter'),
    hidePasted,
    set('hidePasted'),
    prop('fetchedOrders'),
    set('orders'),
    (filter, setFilter, hidePasted, setHide, orders, setOrders) => {
        console.log('change filter', {filter, hidePasted});
        return {
            search: {
                value: filter,
                onChange: (e: any) => {
                    setFilter(e.target.value);
                    const filtered = filterAllOrders(orders, {filter: e.target.value, hide: hidePasted});
                    console.log('22232 filtered search', filtered.length);
                    setOrders(filtered);
                }
            },
            hidePasted: {
                checked: hidePasted === undefined ? true : hidePasted,
                onChange: (value: boolean) => {
                    setHide(value);
                    const filtered = filterAllOrders(orders, {filter, hide: value});
                    setOrders(filtered);
                }
            }
        }
    }
);
const main = createStructuredSelector<ObjectLink<PageStore>, ActionsProps>({
    containerNumber: (s) => s.value?.containerNumber || '...',
    preloaderContainer: () => <Preloader className={'preloader'} />,
    comment,
    //@ts-ignore
    orderTxt,
    page: () => 'Actions',
    number: (s) => s.value?.code as string,
    header,
    state: s => s.value.actionsState,
    cancelCode,
    enterCodeBtn,
    showOrderList,
    code,
    logout,
    fade,
    submitCode,
    completeOrder,
    cancelOrder,
    complete,
    backToOrder,
    scanBarcode,
    preloader: createSelector(
        prop('preloadText'),
        (text) => {
            return <Preloader />
        }
    ),
    errorTxt: (s) => s.value.orderError as string,
    scanner: createSelector(
        (s: ObjectLink<PageStore>) => s,
        findByCode,
        (s, findByCode) => {
            return (
                <Scanner
                    onScan={(code: string) => {
                        return findByCode(code)
                        // s.update({code, actionsState: ActionsState.ORDER_SEARCHING});
                    }}
                    active={!!s.value.barcodeActive}
                    back={() => s.update({actionsState: ActionsState.SELECT, barcodeActive: false})}
                />
            )
        }
    ),
    userMenu: createSelector(prop('userMenuOpen'), (open) => !!open),
    enterCode: createSelector(prop('actionsState'), (s) => s === ActionsState.ENTER_CODE),
    done: createSelector(prop('actionsState'), (s) => s === ActionsState.DONE),
    preloading: createSelector(prop('actionsState'), (s) => s === ActionsState.PRELOADING),
    order: createSelector(prop('actionsState'), (s) => s === ActionsState.ORDER),
    orderCompleted: createSelector(prop('actionsState'), (s) => s === ActionsState.ORDER_COMPLETED),
    scanning: createSelector(prop('actionsState'), (s) => s === ActionsState.SCAN_BARCODE),
    orderSearching: createSelector(prop('actionsState'), (s) => s === ActionsState.ORDER_SEARCHING),
    orderSelect: createSelector(prop('actionsState'), (s) => s === ActionsState.ORDER_SELECT),
    orderError: createSelector(prop('actionsState'), (s) => s === ActionsState.ORDER_ERROR),
    filters,
    orders: createSelector(
        prop('orders'),
        (s: ObjectLink<PageStore>) => s,
        selectOrder,
        (orders: any[], s: ObjectLink<PageStore>, selectOrder) => {
            if (orders?.length) {
                return <>
                    {
                        orders.map((order: any) => {
                            const componentProps = orderToCellSelector(s, order);
                            const res = {
                                ...componentProps,
                                style: {
                                    cursor: 'pointer',
                                },
                                onClick: () => selectOrder(order),
                                services: componentProps.serviceComponents,
                            }
                            return (
                                <PlasmicComponent componentProps={res} component={'OrderRow'}/>
                            )
                        })

                    }</>
            }

            return []
        }
    )
})

//@ts-ignore
export default createSelector<ObjectLink<PageStore>, ActionsProps & ActionsOrder>(
    main,
    actionsOrder,
    (main: any, actionsOrder: any) => {
        return ({
            ...main,
            ...actionsOrder,
        })
    }
)
