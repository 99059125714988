import authFetch from "./utils/authFetch";

export enum Status {
    Created = "created",
    Working = "working",
    Ready = "ready",
    Complete = "complete",
    Paid = "paid"
}


export interface OrderRequest {
    order: string
    status: Status
}

const changeStatus = async ({order, status}:OrderRequest) => {
    const r = await authFetch(`/api/change-order-status?status=${status}&id=${order}`);
    return r.json();
}

export default changeStatus;