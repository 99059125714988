import {createSelector, createStructuredSelector} from "reselect";
import {format} from "date-fns";

export interface OrderToCell {
    skiName: string
    skiNumber: string
    date: string
    services: string[]
    serviceComponents?: any[]
}

export const orderToCellSelector = createStructuredSelector<any, any>({
    skiName: (_: any, order: any) => order?.ski?.brand,
    skiNumber: (_: any, order: any) => order?.ski?.barcode,
    date: (_: any, order: any) => order?.booking?.bookingDate ? format(new Date(order?.booking?.bookingDate), 'dd.MM.yy HH:mm') : 'not setup',
    services: (_: any, order: any) => order?.services.filter((s: any) => !!s).map((s: any) => s?.name),
    serviceComponents: createSelector(
        (_: any, order: any) => order,
        (order: any) => {
            const names = order?.services.filter((s: any) => !!s).map((s: any) => s.name);
            return <>
                {names.map((name: string) => {
                    return (
                        <div
                            style={{
                                fontSize: '0.9rem',
                                color: 'white',
                                paddingRight: '5px'
                            }}>{
                            name
                        }</div>
                    )
                })}
            </>
        }
    )
})