import {ObjectLink} from "@rn-cantons/react-link";
import {createSelector} from "reselect";
import {PageStore} from "../types";

export const update = (link: ObjectLink<PageStore>) => (value: any) => link.update(value)
//@ts-ignore
export const prop = (name: keyof PageStore) => (link: ObjectLink<PageStore>) => {
    console.log('read prop', {name, link: link?.value})
    return link.value[name]
}
export const set = (name: keyof PageStore) => createSelector(
    update,
    (update) => (value: any) => update({[name]: value})
)


export const setupInputUpdateHandler = (name: keyof PageStore) => createSelector(
    update,
    (u: any) => (e: any) => u({[name]: e.target.value})
)
