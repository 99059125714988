import {getAuth, onAuthStateChanged, User} from 'firebase/auth'
import app from "./app";
export type Unsubscribe = () => void;

export default (success: (user: User) => void, reject: () => void): Unsubscribe => {
    return onAuthStateChanged(getAuth(app), (user) => {
        if(user){
            success(user);
        }else{
            reject()
        }
    })
}