
import * as React from "react";
import {
    DefaultBookingSmsInformationProps,
    PlasmicBookingSmsInformation
} from "./plasmic/hiihtocenter/PlasmicBookingSmsInformation";
import {HTMLElementRefOf} from "@plasmicapp/react-web";
export interface BookingSmsInformationProps
    extends DefaultBookingSmsInformationProps {
}

function BookingSmsInformation_(
    props: BookingSmsInformationProps,
    ref: HTMLElementRefOf<"div">
) {
    return <PlasmicBookingSmsInformation root={{ref}} {...props} />;
}

const BookingSmsInformation = React.forwardRef(BookingSmsInformation_);
export default BookingSmsInformation;
